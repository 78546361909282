import React from 'react'
import PropTypes from 'prop-types'
import { StyledChip, ChipText, TooltipChipIcon } from './Chip.styles.js'
import { InfoIcon } from '@stokr/components-library/dist/components/InfoIcon/InfoIcon.js'

const Chip = ({ children, isActive, onClick, tooltip, ...props }) => (
  <StyledChip onClick={onClick} isActive={isActive} {...props}>
    <ChipText>{children}</ChipText>

    {tooltip && (
      <InfoIcon
        title={tooltip}
        position="right"
        noIcon
        noMarginLeft
        noMarginRight
      >
        <TooltipChipIcon />
      </InfoIcon>
    )}
  </StyledChip>
)

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
}

Chip.defaultProps = {
  isActive: false,
  onClick: () => {},
  tooltip: null,
}

export default Chip
