import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Chip from './Chip'
import { Wrapper } from './Chip.styles'
import { Scroll } from 'components/TabsNav/TabsNav.styles'

const ChipsWrapper = ({
  chips,
  onActiveChipChange = () => {},
  customWrapperStyle,
  ...props
}) => {
  const [activeChip, setActiveChip] = useState(null)

  useEffect(() => {
    const initialActiveIndex = chips.findIndex((chip) => chip.isActive)
    if (initialActiveIndex !== -1) {
      setActiveChip(initialActiveIndex)
      onActiveChipChange(initialActiveIndex)
    }
  }, [chips])

  const handleChipClick = (index) => {
    setActiveChip(index)
    onActiveChipChange(index) // Optionally notify parent about the change
  }

  return (
    <Scroll>
      <Wrapper customWrapperStyle={customWrapperStyle}>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            isActive={activeChip === index}
            onClick={() => handleChipClick(index)}
            tooltip={chip.tooltip}
            {...props}
          >
            {chip.label}
          </Chip>
        ))}
      </Wrapper>
    </Scroll>
  )
}

ChipsWrapper.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  onActiveChipChange: PropTypes.func,
  customWrapperStyle: PropTypes.object,
}

export default ChipsWrapper
