import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react'
import {
  Button,
  ComponentWrapper,
  AuthContext,
  PaymentModal,
  CryptoAddress,
  getCurrencySymbol,
  Text,
} from '@stokr/components-library'
import { AnimatedSpan, Container } from './ChartBox.styles'
import {
  Column,
  FlexContainer,
  Row,
} from '@stokr/components-library/dist/components/Grid/Grid.styles'
import RedemptionRequestModal from 'components/Redemption/RedemptionRequestModal'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import NotificationsCarousel from 'components/NotificationsCarousel/NotificationsCarousel'
import { DisplayOnBreakpoint } from '@stokr/components-library/dist/styles/rwd'
import { TransactionTypes } from 'constants/enums'
import ChipsWrapper from 'components/Chips/ChipWrapper'
import {
  addTransactionInfo,
  createTransactionList,
  filterByType,
  sortByDate,
} from 'components/Transactions/Transactions'
import { SectionTitle } from '@stokr/components-library/dist/components/SectionTitle/SectionTitle.styles'
import { Pagination } from '@stokr/components-library/dist/components/Pagination/Pagination'
import {
  Card,
  CardTitle,
} from '@stokr/components-library/dist/components/Card/Card.styles'
import { InputWithButton } from '@stokr/components-library/dist/components/Input/InputWithButton'

const filterChips = [
  { label: 'All', isActive: true },
  { label: 'Redemption' },
  { label: 'Payout' },
]

const transactionTab = (isMobile = false, transactionsInProgress) => (
  <NotificationsCarousel
    //dots
    isMobile={isMobile}
    onlyInvestmentNotifications
    isRedemptionRequest
    carouselTitle="Pending Redemptions"
    arrows
    noBgPattern
    transactionsInProgress={transactionsInProgress}
    noMargin
    fullWidth={isMobile ? false : true}
    sliderSettings={{
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: isMobile ? 1 : 2,
    }}
  />
)

const Redemptions = () => {
  const { user, checkTokenIsValid } = useContext(AuthContext)
  const {
    projects,
    finalizedDistributions,
    pendingDistributions,
    isLoadingData,
    getTokenTransfers,
    documents,
  } = useContext(EventDBContext)

  const [noInvestments, setNoInvestments] = useState(false)
  const [noRedemptionInvestments, setnoRedemptionInvestments] = useState(false)

  const [redeemableAssets, setredeemableAssets] = useState([])
  const [redemptionsInProgress, setredemptionsInProgress] = useState([])
  const [redemptionSuccessData, setredemptionSuccessData] = useState()

  const [filteredFinalizedTx, setFilteredFinalizedTx] = useState([])

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [inputPageSize, setinputPageSize] = useState(pageSize.toString())
  const finalizedTxScroll = useRef(null)

  const currentFinalizedTx = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return filteredFinalizedTx.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, filteredFinalizedTx, pageSize])

  //modal state
  const [isModalOpen, setisModalOpen] = useState({
    redeemRequest: false,
    uploadDocument: false,
    redemptionSuccess: false,
  })

  useEffect(() => {
    let redeemableProjects = projects?.filter((project) => project.isRedeemable)

    if (redeemableProjects?.length) {
      const assetsToAdd = []
      redeemableProjects.forEach((project) => {
        const redemptionSale = project.sales?.find((sale) => sale.isRedemption)

        if (redemptionSale && isRedemptionSaleOpen(redemptionSale)) {
          assetsToAdd.push({
            name: project.tokenSymbol,
            currencies: redemptionSale?.currencies || [],
            conversionPrice: redemptionSale?.conversionPrice,
            project,
          })
        }
      })

      setredeemableAssets(assetsToAdd)
    }
  }, [projects])

  useEffect(() => {
    if (!isLoadingData) {
      const transactions = [...pendingDistributions, ...finalizedDistributions]

      const redemptionTx = transactions.filter(
        (tx) =>
          (tx.isRedemption || tx.type === TransactionTypes.REDEMPTION) &&
          !tx.paidOut,
      )

      redemptionTx.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      redemptionTx.forEach((tx) => {
        tx.handleCTAClick = handleRedemptionsDetailsClick
      })

      const sortedFinalizedTx = sortByDate(finalizedDistributions)

      const withTransactionInfoFinalized = addTransactionInfo(
        sortedFinalizedTx,
        user.wallets,
      )

      setFilteredFinalizedTx(filterByType(withTransactionInfoFinalized))

      setredemptionsInProgress(redemptionTx)
      setnoRedemptionInvestments(redemptionTx.length === 0)
      setNoInvestments(transactions.length === 0)
    }
  }, [pendingDistributions, finalizedDistributions, isLoadingData])

  const toggleModal = useCallback((modalId, newState) => {
    setisModalOpen((prev) => ({
      ...prev,
      [modalId]: newState,
    }))
  }, [])

  const handleRedemptionsDetailsClick = useCallback(
    (transactionData) => {
      const projectFromTx = projects?.find(
        (project) => project._id === transactionData.project,
      )
      transactionData.projectData = projectFromTx
      setredemptionSuccessData(transactionData)

      setisModalOpen((prev) => {
        if (prev.redeemRequest) {
          return {
            ...prev,
            redeemRequest: false,
            redemptionSuccess: true,
          }
        } else {
          return {
            ...prev,
            redemptionSuccess: true,
          }
        }
      })
    },
    [projects, isModalOpen.redeemRequest, toggleModal],
  )

  const handleActiveChipChange = (index) => {
    const activeFilter = filterChips[index]?.label
    const sortedFinalizedTx = sortByDate(finalizedDistributions)

    const withTransactionInfoFinalized = addTransactionInfo(
      sortedFinalizedTx,
      user.wallets,
    )
    setFilteredFinalizedTx(
      filterByType(withTransactionInfoFinalized, activeFilter),
    )
  }

  function renderFinalizedTable() {
    return filteredFinalizedTx.length > 0 ? (
      <>
        <ComponentWrapper
          noPaddingHorizontal
          noPaddingTop
          ref={finalizedTxScroll}
        >
          <SectionTitle style={{ fontWeight: 700, fontSize: 18 }}>
            Finalised transactions
          </SectionTitle>
        </ComponentWrapper>
        {createTransactionList(currentFinalizedTx)}
        <ComponentWrapper
          noPaddingHorizontal
          flex
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalCount={finalizedDistributions.length}
            pageSize={pageSize}
            onPageChange={(page) => {
              setCurrentPage(page)

              //scroll into view with vertical offset (to avoid fixed header overlap)
              const yOffset = -100
              const element = finalizedTxScroll.current
              if (element) {
                const y =
                  element?.getBoundingClientRect().top +
                  window.pageYOffset +
                  yOffset

                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          />
          <Card noPadding>
            <CardTitle style={{ padding: '2px 20px' }}>Page Size:</CardTitle>
            <InputWithButton
              id="pageSize"
              name="pageSize"
              value={inputPageSize}
              type="number"
              placeholder={'Page size'}
              onChange={(e) => setinputPageSize(Number(e.target.value))}
            >
              <Button onClick={() => setPageSize(Number(inputPageSize))}>
                apply
              </Button>
            </InputWithButton>
          </Card>
        </ComponentWrapper>
      </>
    ) : (
      ''
    )
  }

  //if loading, return loading message
  if (isLoadingData) {
    return (
      <Container>
        <ComponentWrapper center>
          <Text blockchainLoadingMsg>
            <h4>
              A few seconds left. We are still reading the data out of the
              blockchain{' '}
              <AnimatedSpan fontSize={20}>
                <span data-order="0">.</span>
                <span data-order="1">.</span>
                <span data-order="2">.</span>
              </AnimatedSpan>
            </h4>
          </Text>
        </ComponentWrapper>
      </Container>
    )
  }

  return (
    <>
      <Row center>
        <Column part={8}>
          <ComponentWrapper
            flex
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 32,
            }}
            noPaddingHorizontal
          >
            <ChipsWrapper
              chips={filterChips}
              onActiveChipChange={handleActiveChipChange}
            />

            <Button
              // minWidth="240px"
              onClick={() => {
                checkTokenIsValid()
                toggleModal('redeemRequest', true)
              }}
              disabled={!redeemableAssets.length}
            >
              Redeem
            </Button>
          </ComponentWrapper>
          {redemptionsInProgress?.length > 0 && (
            <ComponentWrapper center noPadding>
              <DisplayOnBreakpoint up breakpoint="Medium">
                {transactionTab(false, redemptionsInProgress)}
              </DisplayOnBreakpoint>
              <DisplayOnBreakpoint down breakpoint="Medium">
                {transactionTab(true, redemptionsInProgress)}
              </DisplayOnBreakpoint>
            </ComponentWrapper>
          )}
          <Container>
            <ComponentWrapper style={{ width: '100%' }} noPadding>
              {noInvestments ? (
                <>
                  <Text>
                    <h4>
                      You have not made any investments yet. You want to see
                      what you can invest in? Please discover Investment
                      Opportunities.
                    </h4>
                  </Text>
                  <Button
                    style={{ marginTop: '3rem' }}
                    onClick={() =>
                      window.location.replace(
                        `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/featured-assets`,
                      )
                    }
                    id="invest-opportunities-redemption-button"
                  >
                    INVESTMENT OPPORTUNITIES
                  </Button>
                </>
              ) : (
                // redeemableAssets.length >= 0 && (
                //   <>
                //     {noRedemptionInvestments && (
                //       <ComponentWrapper noPaddingHorizontal>
                //         <Text>
                //           <h4>You have not requested any redemptions yet.</h4>
                //         </Text>
                //       </ComponentWrapper>
                //     )}
                //     <FlexContainer style={{ gap: '32px', flexWrap: 'wrap' }}>
                //       <Button
                //         minWidth="240px"
                //         onClick={() => {
                //           checkTokenIsValid()
                //           toggleModal('redeemRequest', true)
                //         }}
                //         disabled={!redeemableAssets.length}
                //       >
                //         Redeem
                //       </Button>
                //     </FlexContainer>
                //   </>
                // )
                renderFinalizedTable()
              )}
            </ComponentWrapper>

            <RedemptionRequestModal
              isModalOpen={isModalOpen.redeemRequest}
              user={user}
              redeemableAssets={redeemableAssets}
              transactions={redemptionsInProgress}
              onModalClose={() => toggleModal('redeemRequest', false)}
              onSuccess={handleRedemptionsDetailsClick}
            />
            <PaymentModal
              isModalOpen={isModalOpen.redemptionSuccess}
              user={user}
              data={redemptionSuccessData}
              txTextInRed={false}
              formatAmountInLink
              modalTitle={`SEND YOUR ${
                redemptionSuccessData?.tokenSymbol
              } to receive your 
              ${getCurrencySymbol(redemptionSuccessData?.currencyType)}`}
              sendTokenTextRight={
                <div style={{ marginBottom: '1em' }}>
                  <p style={{ margin: 0, display: 'inline' }}>
                    Send {redemptionSuccessData?.tokenAmount}{' '}
                    {getCurrencySymbol(redemptionSuccessData?.tokenSymbol)} to
                    the treasury wallet below. You will receive{' '}
                    {redemptionSuccessData?.currencyAmount}{' '}
                    {getCurrencySymbol(redemptionSuccessData?.currencyType)} on
                    your AMP wallet:{' '}
                  </p>
                  <CryptoAddress
                    noHead
                    data={{
                      value: redemptionSuccessData?.GAID,
                      tooltip: true,
                      shortAddress: true,
                    }}
                    containerStyle={{ display: 'inline-block', height: 20 }}
                    fontSize={16}
                  />
                </div>
              }
              isRedemption
              onCTAClick={() => {
                toggleModal('redemptionSuccess', false)
                setredemptionSuccessData()
                getTokenTransfers(projects, documents, true)
              }}
              onModalClose={() => {
                toggleModal('redemptionSuccess', false)
                setredemptionSuccessData()
                getTokenTransfers(projects, documents, true)
              }}
            />
          </Container>
        </Column>
      </Row>
    </>
  )
}

const isRedemptionSaleOpen = (sale) => {
  const { startDate, endDate } = sale

  let today = new Date().getTime()
  const startTime = new Date(startDate).getTime()
  const endTime = new Date(endDate).getTime()

  return startTime < today && today < endTime
}

export default Redemptions
