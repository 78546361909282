import styled, { keyframes } from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  z-index: 20;

  text-align: left;
  width: 100%;

  ${rwd.Medium`
    align-items: start;
    padding: 64px 0;
    flex-direction: row;
  `}

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const fadeInOut = keyframes`
  50% {
    color: transparent;
  }
`

export const AnimatedSpan = styled.div`
  margin: 0;
  display: inline-block;

  span {
    animation: ${fadeInOut} 1s infinite;
    font-size: ${(props) => props.fontSize || 20}px;
  }

  span[data-order='1'] {
    animation-delay: 250ms;
  }

  span[data-order='2'] {
    animation-delay: 500ms;
  }
`

export const Chart = styled.div`
  display: block;
`

export const Legend = styled.div`
  display: block;
  width: 100%;
  padding-top: 64px;
  padding-left: 10px;

  ${rwd.Medium`
    width: auto;
    flex-grow: 1;
    padding-top: 0;
    padding-left: 64px;

    ${(props) =>
      props.spaceTop &&
      `
      padding-top: 104px;
    `}
  `}

  ${rwd.Large`
    max-width: 768px;
  `}
`

export const SelectWrapper = styled.div`
  padding-bottom: 64px;
`
