import React, { useContext, useEffect, useRef, useState } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { ComponentWrapper, Column, Row } from '@stokr/components-library'
import ProfileHeader from 'components/ProfileHeader/ProfileHeader'
import TabsNav, { TabNav } from 'components/TabsNav/TabsNav'
import LearnMore from 'components/LearnMoreSection/LearnMore'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import { ProjectStates, ProjectStatus } from 'constants/enums'
import { generateProjectState } from 'utils/getProjectState'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

var learnMoreObject = {
  categories: [
    {
      id: ProjectStates.LIVE,
      title: 'Live',
      inProgress: true,
      complete: false,
      tooltip: 'Example tooltip',
      subcategories: [],
    },
    {
      id: ProjectStates.UPCOMING,
      title: 'Upcoming',
      inProgress: false,
      complete: false,
      subcategories: [],
    },
    {
      id: ProjectStates.CLOSED,
      title: 'Closed',
      inProgress: false,
      complete: false,
      subcategories: [],
    },
  ],
  items: [],
}
export const createItem = (project) => {
  return {
    id: project._id,
    title: project.title,
    excerpt: project.excerpt,

    cover: project.coverimage,
    url: `https://stokr-staging.de/${project.name}`,
    category: project.category || '',
  }
}

export const createSubcategory = (project) => {
  return {
    id: project._id,
    title: project.title,
    inProgress: true, //what is this for?
    complete: false,
    tooltip: 'Reading time: 2min',
  }
}

const navTabs = [
  {
    id: 0,
    title: 'Overview',
    path: '/overview',
  },
  {
    id: 1,
    title: 'Investments',
    path: '/transactions',
  },
  {
    id: 2,
    title: 'Distributions',
    path: '/redemptions',
  },
  {
    id: 3,
    title: 'Documents',
    path: '/documents',
  },
]

const DashboardPage = ({ isChecklist, ...props }) => {
  const [activeTab, setactiveTab] = useState(0)
  const [learnMore, setlearnMore] = useState(learnMoreObject)
  const [loadingLearnMore, setloadingLearnMore] = useState(true)

  const tabScroll = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  const { projects } = useContext(EventDBContext)

  useEffect(() => {
    setLearnMoreObject()
  }, [projects])

  useEffect(() => {
    const redirectUrlCookie = Cookies.get('STOKR_REDIRECT_URL')
    if (redirectUrlCookie) {
      Cookies.remove('STOKR_REDIRECT_URL')
      navigate(redirectUrlCookie)
    } else {
      const isNavTabOpen = navTabs.find((tab) => location.pathname === tab.path)
      if (isNavTabOpen) {
        setactiveTab(isNavTabOpen.id)
        //call onTabChange to update scroll ref
        setTimeout(() => {
          onTabChange(isNavTabOpen)
        }, 800)
      }
    }
  }, [])

  const setLearnMoreObject = () => {
    if (projects && projects.length > 0 && loadingLearnMore) {
      //filter only active projects so we can sort them
      var activeProjects = projects.filter(
        (project) =>
          project.status === ProjectStatus.ACTIVE ||
          project.status === ProjectStatus.ON_HOLD,
      )

      //display the newer projects first
      var sortedProjects = activeProjects.sort((a, b) => {
        return Number(a.trending_order) - Number(b.trending_order)
      })

      sortedProjects.forEach((project) => {
        let item = createItem(project)
        let subcategory = createSubcategory(project)

        learnMoreObject.items.push(item)

        const ProjectState = generateProjectState(project)

        learnMoreObject.categories
          .find((x) => x.id === ProjectState)
          .subcategories.push(subcategory)
      })
      setlearnMore(learnMoreObject)
      setloadingLearnMore(false)
    }
  }

  const onTabChange = (tab) => {
    if (activeTab !== tab.id) {
      setactiveTab(tab.id)
    }
    navigate(tab.path)

    const tabsNavOffsetTop = tabScroll.current?.offsetTop

    // Tabs nav top position - header height
    let scrollToPosition = tabsNavOffsetTop - 96

    // If mobile view, don't subtract header height
    if (window.innerWidth < 768) {
      scrollToPosition = tabsNavOffsetTop
    }

    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
  }

  return (
    <>
      <Layout>
        <SEO
          title="Dashboard"
          keywords={['STOKR', 'dashboard', 'tokenisation']}
        />
        <Row main>
          <Column>
            <ProfileHeader isChecklist={isChecklist} />
            <ComponentWrapper noPadding ref={tabScroll}>
              <TabsNav activeTab={activeTab}>
                {navTabs.map((tab) => (
                  <TabNav
                    tabId={tab.id}
                    key={tab.id}
                    onClick={() => onTabChange(tab)}
                  >
                    {tab.title}
                  </TabNav>
                ))}
              </TabsNav>
              <Outlet />
            </ComponentWrapper>
          </Column>
          <Column>
            <ComponentWrapper noPadding borderTop>
              <LearnMore
                categories={learnMore.categories}
                items={learnMore.items}
                loading={loadingLearnMore}
              />
            </ComponentWrapper>
          </Column>
        </Row>
      </Layout>
    </>
  )
}

export default DashboardPage
