import { Button } from '@stokr/components-library'
import { theme } from '@stokr/components-library/dist/styles/theme'
import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 32px 5px;
  z-index: 10;

  text-align: left;
  width: 100%;

  ${rwd.Medium`
    align-items: start;
    padding: 64px 0;
    flex-direction: row;
  `}
`

export const CSVButton = styled(Button)`
  & svg {
    margin-left: 8px;
    width: 14px;
    height: 14px;
    vertical-align: sub;
    color: ${theme.cPrimary};
  }

  &:hover {
    & svg {
      color: ${theme.cWhite};
    }
  }
`
