import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loader from 'static/images/process-waiting.gif'

import {
  Table,
  Row,
  Label,
  Value,
  Link,
  DocumentBox,
} from './BasicTable.styles'
import { fetchFile } from 'api/fetch-file'
import { saveAs, openFile } from 'utils/saveAs'
import { ReactComponent as DownloadIcon } from 'static/images/download_icon.svg'

const BasicTable = ({ data, files, investmentId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)

  const handleFileClick = async (file) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      const result = await fetchFile('documents/getfile', {
        documentId: file._id,
      })
      const blobData = new Blob([result.data], {
        type: 'application/pdf',
      })
      // saveAs(blobData, file.fileName)
      openFile(blobData)
      setIsLoading(false)
    } catch (error) {
      console.log('🚀 ~ error', error)
      setIsLoading(false)
    }
  }

  return (
    <Table>
      {data.map((item, itemKey) =>
        item.isLink ? (
          item.value !== 'undefined' && (
            <>
              <Row key={item.label}>
                <Label>{item.label}</Label>

                <Value leftalign>
                  <Link
                    href={`${item.link}${item.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.value}
                  </Link>
                </Value>
              </Row>
            </>
          )
        ) : item.isDocument ? (
          <Row key={item.label}>
            <Label width={'60%'}>
              <Link
                key={item._id}
                onClick={() => {
                  handleFileClick(item)
                }}
                style={{ cursor: 'pointer', display: 'block' }}
              >
                {item.label}
              </Link>
            </Label>

            <Value width={'35%'}>{item.value}</Value>
          </Row>
        ) : (
          <Row key={item.label}>
            <Label>{item.label}</Label>
            <Value>
              {item.isStrong ? <strong>{item.value}</strong> : item.value}
            </Value>
          </Row>
        ),
      )}
      {files && files.length > 0 && (
        <Row key={50}>
          <Label>{'Documents'}</Label>
          {isLoading && <img src={loader} alt="loading" />}
          <Value
            downloadDocument
            //onMouseEnter={() => setIsDocModalOpen(true)}
            //onMouseLeave={() => setIsDocModalOpen(false)}
            onClick={() => setIsDocModalOpen(!isDocModalOpen)}
          >
            Download Documents
            <DownloadIcon />
            {isDocModalOpen && (
              <DocumentBox onClose={() => setIsDocModalOpen(false)}>
                {files.map((file) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <DownloadIcon />
                      <Link
                        key={file._id}
                        onClick={() => {
                          handleFileClick(file)
                        }}
                        style={{ cursor: 'pointer', display: 'block' }}
                      >
                        {file.fileName}
                      </Link>{' '}
                    </div>
                  </>
                ))}
              </DocumentBox>
            )}
          </Value>
        </Row>
      )}
    </Table>
  )
}
BasicTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BasicTable
