import React, { useEffect, useContext, useState } from 'react'
import {
  EventDBContext,
  getMarketInfoString,
} from 'context/EventDBContext/EventDBContext'
import DonutChart from 'components/DonutChart/DonutChart'
import {
  DonutChartLabel,
  DonutChartValue,
} from 'components/DonutChart/DonutChart.styles'
import {
  Button,
  ChartLegend,
  ComponentWrapper,
  km_ify,
  AuthContext,
  Select,
  Text,
  getProjectCurrencySign,
} from '@stokr/components-library'
import BasicTable from 'components/BasicTable/BasicTable'
import styled from 'styled-components'
import {
  Container,
  Chart,
  Legend,
  SelectWrapper,
  AnimatedSpan,
} from './ChartBox.styles'
import NotificationsCarousel from 'components/NotificationsCarousel/NotificationsCarousel'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { TransactionTypes } from 'constants/enums'
import ArtworkComponent from 'components/Artwork/ArtworkComponent'
import { isEmpty } from 'lodash'

const NoInvestmentChart = styled.div`
  width: 30%;
  float: left;
  padding-right: 5rem;

  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: 767px) and (min-width: 480px) {
    width: 100%;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    padding-right: 0;
  }
`

const NoInvestmentText = styled.div`
  width: 70%;
  float: left;
  padding-left: 5rem;
  padding-top: 20rem;

  h4 {
    width: 80%;
  }

  @media screen and (max-width: 1200px) and (min-width: 768px) {
    padding-top: 16rem;
    h4 {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding-left: 2rem;
    padding-top: 5rem;

    h4 {
      width: 100%;
    }
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    padding-left: 0;
  }
`

const noData = [
  {
    color: '#f0f0f0',
    name: 'nothing found',
    price: 1,
    subname: 'no sub name',
    value: 102,
  },
]

const notificationTab = (isMobile = false, transactionsInProgress) => (
  <NotificationsCarousel
    //dots
    arrows
    carouselTitle="Recent transactions"
    isMobile={isMobile}
    onlyInvestmentNotifications
    transactionsInProgress={transactionsInProgress}
  />
)

const Table = (
  balance,
  symbol,
  price,
  address,
  link,
  label,
  projectName,
  marketData,
) => {
  return (
    <BasicTable
      data={[
        {
          label:
            projectName === 'techforgood' ? 'Donation amount' : 'Token balance',
          value: balance.toString(),
        },
        {
          label: `${!isEmpty(marketData) ? 'Market' : ''} Token price`,
          value: !isEmpty(marketData)
            ? `
           ${
             marketData.isBTCIndex ? '₿' : symbol
           } ${marketData?.lastPriceCalculated.toFixed(
                marketData?.isBTCIndex ? 8 : 2,
              )}`
            : `${symbol} ${price.toFixed(2)}`,
        },
        {
          label: `${!isEmpty(marketData) ? 'Market' : ''} Token value`,
          value: !isEmpty(marketData)
            ? `${marketData.isBTCIndex ? '₿' : symbol} ${(
                balance * marketData.lastPriceCalculated
              ).toFixed(marketData?.isBTCIndex ? 8 : 2)}`
            : `${symbol} ${(balance * price).toFixed(2)}`,
        },
        { label: label, value: address, isLink: true, link },
      ]}
    />
  )
}

const numberOfRecentTx = 20

const ChartBox = () => {
  const [transactionsInProgress, setTransactionsInProgress] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [noInvestments, setNoInvestments] = useState(false)
  const [fetchedBalances, setFetchedBalances] = useState()
  const [balances, setBalances] = useState()
  const [chartData, setChartData] = useState()
  const [dropdownOptions, setDropdownOptions] = useState([
    { key: '1', value: 'all', label: 'ALL ADDRESSES' },
  ])
  const [activeAddress, setActiveAddress] = useState({
    value: 'all',
    label: 'ALL ADDRESSES',
  })
  const [totalValue, setTotalValue] = useState(0)
  const { user } = useContext(AuthContext)
  const { tokenBalances, tokenTransfers, finalizedTx, pendingTx, hasArtwork } =
    useContext(EventDBContext)

  useEffect(() => {
    const { wallets = [] } = user

    const genericWallets = wallets.filter((wallet) => !wallet.assetIds)

    // Generate dropdown list items
    const dropdownOptionsList = [
      { key: '1', value: 'all', label: 'ALL ADDRESSES' },
      ...genericWallets.map((wallet) => ({
        key: wallet.address,
        value: wallet.address,
        label: wallet.type === 'liquid' ? wallet.address : wallet.name,
      })),
    ]

    setDropdownOptions(dropdownOptionsList)

    //join  pending and finalized tx into one
    var transactions = [].concat(pendingTx, finalizedTx)

    //filter out secondary market tx and redemptions and note split tx
    transactions = transactions.filter(
      (tx) =>
        tx.type !== TransactionTypes.TRANSFER &&
        tx.type !== TransactionTypes.REDEMPTION &&
        tx.type !== TransactionTypes.NOTE_SPLIT &&
        !tx.isRedemption,
    )

    // sort array in descending order
    transactions.sort(function (item1, item2) {
      if (item1.createdAt > item2.createdAt) return -1
      if (item1.createdAt < item2.createdAt) return 1
      return 0
    })

    // Get the 20 most recent transactions
    const recentTransactions = transactions.slice(0, numberOfRecentTx)

    setTransactionsInProgress(recentTransactions)

    // In case there are only pending euro tx older than 7 days
    setNoInvestments(transactions.length === 0)
  }, [user.wallets, tokenTransfers])

  useEffect(() => {
    const loadingCompleted = !!tokenBalances
    const balanceMoreThan0 =
      tokenBalances && tokenBalances.find((x) => x.balance > 0)
    const investmentsExist = loadingCompleted && balanceMoreThan0

    setIsLoading(!loadingCompleted)
    setNoInvestments(!investmentsExist)
    if (investmentsExist) {
      setFetchedBalances({ tokenBalances })
    }
  }, [tokenBalances])

  // Trigger after balances are load and/or selected address changes
  useEffect(() => {
    if (fetchedBalances) {
      // Generate table data
      var dataByTokenAddress = []

      //tokenBalnces hold all the required data
      fetchedBalances.tokenBalances.forEach((tokenAddress, index) => {
        const {
          balance: tokenTotalBalance,
          account: walletAddress,
          tokenName,
          issuanceType,
          marketData = {},
          tokenSymbol,
          tokenCurrency,
          tokenPrice,
          tokenPriceInEUR,
          projectName,
        } = tokenAddress

        let active =
          activeAddress.value === 'all'
            ? true
            : activeAddress.value === walletAddress

        //add data only if balance more than 0 and active adressses equals balance address
        if (tokenTotalBalance > 0 && active) {
          //define variables
          let value = parseFloat(
            tokenTotalBalance.toFixed(tokenAddress.tokenDecimals || 2),
          )

          let label = issuanceType === 'liquid' ? 'LIQUID ASSET ID' : ''

          const {
            isBTCIndex,
            lastPrice,
            sideSwapUrl,
            isPositive,
            changePercentage,
            last30DaysPriceChange,
            lastPriceCalculated,
          } = marketData

          //add data to array
          dataByTokenAddress.push({
            value,
            subname: tokenName,
            name: tokenSymbol,
            info: changePercentage
              ? `${
                  isBTCIndex ? '₿' : getProjectCurrencySign(tokenCurrency)
                }   ${
                  isBTCIndex
                    ? (value * lastPrice).toFixed(8)
                    : km_ify(value * (lastPriceCalculated || tokenPrice), 2)
                }`
              : null,
            onNameClick: sideSwapUrl ? handleTokenNameClick : null,
            price: tokenPriceInEUR,
            color: dataByTokenAddress.length % 2 === 0 ? '#0050ca' : '#ee220d',
            marketValue: changePercentage ? `${changePercentage} %` : '',
            marketInfo: last30DaysPriceChange
              ? getMarketInfoString(value, marketData, tokenCurrency)
              : null,
            isPositive: isPositive ?? null,
            marketData,
            details: Table(
              value,
              tokenCurrency === 'USD' ? '$' : '€',
              Number(tokenPrice),
              tokenAddress.asset,
              tokenAddress.blockhainLink,
              label,
              projectName,
              marketData,
            ),
          })

          let groupedDataMap = new Map()

          //group token address into one
          dataByTokenAddress.forEach((item) => {
            if (groupedDataMap.has(item.name)) {
              const existingItem = groupedDataMap.get(item.name)
              existingItem.value = parseFloat(
                (existingItem.value + item.value).toFixed(
                  tokenAddress.tokenDecimals || 2,
                ),
              )
              existingItem.info = changePercentage
                ? `${
                    isBTCIndex ? '₿' : getProjectCurrencySign(tokenCurrency)
                  } ${
                    isBTCIndex
                      ? (existingItem.value * lastPrice).toFixed(8)
                      : km_ify(value * (lastPriceCalculated || tokenPrice))
                  }`
                : null
              existingItem.marketInfo = last30DaysPriceChange
                ? getMarketInfoString(
                    existingItem.value,
                    marketData,
                    tokenCurrency,
                  )
                : null

              existingItem.details = Table(
                existingItem.value,
                tokenAddress.tokenCurrency === 'USD' ? '$' : '€',
                Number(tokenAddress.tokenPrice),
                tokenAddress.asset,
                tokenAddress.blockhainLink,
                label,
                projectName,
                marketData,
              )
            } else {
              groupedDataMap.set(item.name, { ...item })
            }
          })

          dataByTokenAddress = Array.from(groupedDataMap.values())
        }
      })

      setBalances(dataByTokenAddress)

      // Remove details to not break the chart
      const chartDataArray = dataByTokenAddress.map(
        ({ details, ...rest }) => rest,
      )
      setChartData(chartDataArray)

      setNoInvestments(dataByTokenAddress.length === 0)

      // Generate total value
      const totalValue = dataByTokenAddress.reduce(
        (accumulator, item) => (accumulator += item.price * item.value),
        0,
      )

      setTotalValue(totalValue.toFixed(2))
    }
  }, [activeAddress, fetchedBalances])

  const handleTokenNameClick = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(data.marketData?.sideSwapUrl, '_blank')
  }

  //if loading, return loading message
  if (isLoading) {
    return (
      <Container>
        <ComponentWrapper center>
          <Text blockchainLoadingMsg>
            <h4>
              A few seconds left. We are still reading the data out of the
              blockchain{' '}
              <AnimatedSpan fontSize={20}>
                <span data-order="0">.</span>
                <span data-order="1">.</span>
                <span data-order="2">.</span>
              </AnimatedSpan>
            </h4>
          </Text>
        </ComponentWrapper>
      </Container>
    )
  }

  return (
    <>
      {transactionsInProgress?.length > 0 && (
        <ComponentWrapper center noPadding>
          <DisplayOnBreakpoint up breakpoint="Medium">
            {notificationTab(false, transactionsInProgress)}
          </DisplayOnBreakpoint>
          <DisplayOnBreakpoint down breakpoint="Medium">
            {notificationTab(true, transactionsInProgress)}
          </DisplayOnBreakpoint>
        </ComponentWrapper>
      )}
      <Container>
        {chartData && balances && !noInvestments ? (
          <>
            <Chart>
              <SelectWrapper>
                <Select
                  id="addresses"
                  name={activeAddress.label}
                  value={activeAddress.value}
                  options={dropdownOptions}
                  label=""
                  onChange={(e) => {
                    setActiveAddress({
                      value: e.value,
                      label: e.label,
                    })
                  }}
                  onBlur={(e) => {}}
                />
              </SelectWrapper>

              <DonutChart data={chartData}>
                <DonutChartLabel strong>Total value</DonutChartLabel>
                <DonutChartValue fontSize="45">
                  € {km_ify(totalValue)}
                </DonutChartValue>
              </DonutChart>
            </Chart>

            <Legend spaceTop>
              <ChartLegend data={balances} />
            </Legend>
          </>
        ) : (
          <NoInvestmentMessage
            activeAddress={activeAddress}
            setActiveAddress={setActiveAddress}
            dropdownOptions={dropdownOptions}
          />
        )}
      </Container>

      {/* show artwork if invetsor has kpmg */}
      {chartData && balances && hasArtwork && (
        <ComponentWrapper style={{ paddingBottom: 64 }}>
          <ArtworkComponent
            artSrc={`${process.env.PUBLIC_URL}/KPMG_Artwork.jpg`}
            artistName={'Artist: Ben Carter'}
            title={'Skatepark / City Scape / Industrial Heritage'}
          />
        </ComponentWrapper>
      )}
    </>
  )
}

const NoInvestmentMessage = ({
  activeAddress,
  setActiveAddress,
  dropdownOptions,
}) => (
  <ComponentWrapper style={{ width: '100%' }}>
    <NoInvestmentChart>
      <Chart>
        <SelectWrapper>
          <Select
            id="addresses"
            name={activeAddress.label}
            value={activeAddress.value}
            options={dropdownOptions}
            label=""
            onChange={(e) => {
              setActiveAddress({
                value: e.value,
                label: e.label,
              })
            }}
            onBlur={(e) => {}}
          />
        </SelectWrapper>

        <DonutChart data={noData}>
          <>
            <DonutChartLabel strong>No Investment Yet</DonutChartLabel>
          </>
        </DonutChart>
      </Chart>
    </NoInvestmentChart>
    <NoInvestmentText>
      <Text>
        <h4>
          You have not made any investments yet. You want to see what you can
          invest in? Please discover Investment Opportunities.
        </h4>
      </Text>
      <Button
        style={{ marginTop: '3rem' }}
        onClick={() =>
          window.location.replace(
            `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/featured-assets`,
          )
        }
        id="invest-opportunities-button"
      >
        INVESTMENT OPPORTUNITIES
      </Button>
    </NoInvestmentText>
  </ComponentWrapper>
)

export default ChartBox
