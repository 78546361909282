import { theme } from '@stokr/components-library/dist/styles/theme'
import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Table = styled.div`
  display: block;
  border: 1px solid ${(props) => props.theme.cLightGrey};
`

export const Row = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  padding: 16px 24px;

  ${rwd.XLarge`
    padding: 16px 46px;
  `}

  & + & {
    border-top: 1px solid ${(props) => props.theme.cLightGrey};
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
`

export const Label = styled.div`
  float: left;

  ${(props) =>
    props.width &&
    `
    width: ${props.width};
    word-break:break-all;
  `}
`

export const Value = styled.div`
  float: right;
  word-break: break-all;
  ${(props) =>
    props.leftalign &&
    `
    text-align: left;
    float: left;
    width: 100%;
  `}

  ${(props) =>
    props.investmentdocs &&
    `
    text-align: right;
    @media screen and (max-width: 920px) {
      text-align: left;
      float: left;
      width: 100%;
    }
  `}
    ${(props) =>
    props.width &&
    `
    width: ${props.width};
    text-align:end;
  `}

  ${(props) =>
    props.downloadDocument &&
    `
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme.cPrimary};
    position: relative;

  `}
`

export const Link = styled.a`
  color: ${(props) => props.theme.cPrimary};
  transition: all 0.2s;

  &:hover {
    color: ${(props) => props.theme.cPrimary};
    text-decoration: underline;
  }
`

export const DocumentBox = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  min-width: 500px;
  z-index: 1000;
  background-color: ${theme.cWhite};
  //border: 1px solid ${theme.cLightGrey};
  padding: 16px;
  box-shadow: 0 2px 4px ${theme.cLightGrey};
`
