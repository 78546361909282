import React, { useEffect, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import Slick from 'react-slick'
import { get } from 'lodash'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NotificationItem from 'components/NotificationItem/NotificationItem'
import UserChannel from 'components/NotificationItem/UserChannel'
import { NotificationContext } from 'context/NotificationContext/NotificationContext'
import moment from 'moment'
import {
  ArrowButton,
  ArrowWrapper,
  CarouselTitle,
  Container,
  Offset,
  TitleWithArrowWrapper,
  Wrapper,
} from './NotificationsCarousel.styles'
import { TransactionTypes } from 'constants/enums'
import { Icon2 } from '@stokr/components-library/dist/components/Icon/Icon2'

const allTransferSteps = [
  {
    index: 0,
    label: 'INVESTMENT CONTRACT SIGNED',
  },
  {
    index: 1,
    label: 'INVESTMENT AMOUNT RECEIVED',
  },
  {
    index: 2,
    label: 'SECURITIES ARE RESERVED FOR YOU',
  },
  {
    index: 3,
    label: 'SECURITIES HAVE BEEN ISSUED TO YOU (after the offering is closed)',
  },
]

const DonationTransferSteps = [
  {
    index: 0,
    label: 'DONATION CONTRACT SIGNED',
  },
  {
    index: 1,
    label: 'DONATION AMOUNT RECEIVED',
  },
  {
    index: 2,
    label: 'SKT IS RESERVED FOR YOU',
  },
  {
    index: 3,
    label: 'SKT HAVE BEEN ISSUED TO YOU (after the offering is closed)',
  },
]

const redemptionSteps = [
  {
    index: 0,
    label:
      'REDEMPTION REQUEST RECEIVED - Send your securities to the treasury wallet',
  },
  {
    index: 1,
    label: (
      <>
        SECURITIES RECEIVED ON THE TREASURY WALLET - Please contact{' '}
        <a
          href="mailto:support@stokr.io"
          style={{ color: 'inherit', textDecoration: 'underline' }}
        >
          support@stokr.io
        </a>{' '}
        in case you don't receive the payout in your AMP wallet after the
        upcoming payout session
      </>
    ),
  },
  {
    index: 2,
    label: 'REDEMPTION COMPLETED',
  },
]

const NotificationsCarousel = ({
  children,
  autoplay,
  dots,
  arrows,
  carouselTitle,
  isMobile,
  onlyInvestmentNotifications,
  transactionsInProgress,
  dataSet,
  notifications,
  isRedemptionRequest,
  noBgPattern,
  noMargin,
  fullWidth,
  sliderSettings,
}) => {
  const { toggleIsRead, isUserChannel } = useContext(NotificationContext)
  var sliderRef = useRef()

  const settings = {
    dots,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: !!autoplay,
    autoplaySpeed: autoplay,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 2,
    initialSlide: 0,
    width: '200px',
    ...sliderSettings,
  }

  const getTxData = (tx) => {
    const isTokenIssued = tx.fulfilled

    const { doneIndex, allSteps } = getTxProgressState(tx)

    const txDate = moment(tx.createdAt).format('DD MMMM YYYY')

    const investment_sent = {
      type: 'TRANSFER_IN_PROGRESS',
      date: txDate,
      title: '',
      content: {
        progress: {
          allSteps: allSteps,
          doneIndex: doneIndex,
          isRedemption:
            tx.type === TransactionTypes.REDEMPTION || tx.isRedemption,
        },
        transfer: {
          to: {
            unit: tx.currencyType,
            value: tx.secondaryCurrency
              ? tx.currencyAmount / tx.secondaryCurrencyinEURorUSD
              : tx.currencyAmount,
          },
          from: {
            unit: tx.tokenSymbol,
            value: Math.abs(tx.tokenAmount),
          },
          tokenDecimals: tx.tokenDecimals,
        },
      },
    }
    if (tx.type === TransactionTypes.REDEMPTION || tx.isRedemption) {
      let temp = investment_sent.content.transfer.to
      investment_sent.content.transfer.to =
        investment_sent.content.transfer.from
      investment_sent.content.transfer.from = temp

      if (!isTokenIssued) {
        investment_sent.content.cta_label = 'View details'
        investment_sent.content.cta_url = 'some url'
        investment_sent.handleCTAClick = tx.handleCTAClick
      }
    }
    dataSet = investment_sent
  }

  const isFakeNotification = window.screen.width > 767

  return (
    <Container noPattern={noBgPattern}>
      <Offset noMargin={noMargin}>
        {carouselTitle && (
          <TitleWithArrowWrapper fullWidth={fullWidth}>
            <CarouselTitle>{carouselTitle}</CarouselTitle>
            {arrows && (
              <ArrowWrapper>
                <ArrowButton onClick={() => sliderRef.slickPrev()}>
                  <Icon2 name="ArrowSimple" direction={'left'} />
                </ArrowButton>
                <ArrowButton onClick={() => sliderRef.slickNext()}>
                  <Icon2
                    name="ArrowSimple"
                    direction={'right'}
                    style={{ marginLeft: 1.5 }}
                  />
                </ArrowButton>
              </ArrowWrapper>
            )}
          </TitleWithArrowWrapper>
        )}
        <Wrapper fullWidth={fullWidth}>
          <Slick {...settings} ref={(slider) => (sliderRef = slider)}>
            {transactionsInProgress?.length > 0 &&
              transactionsInProgress.map((data, index) => {
                return (
                  <NotificationItem
                    key={index}
                    callFn={getTxData(data)}
                    type={get(dataSet, 'type', '')}
                    date={get(dataSet, 'date', '')}
                    title={get(dataSet, 'title', '')}
                    allSteps={get(dataSet, 'content.progress.allSteps')}
                    text={get(dataSet, 'text', '')}
                    progress={get(dataSet, 'content.progress', '')}
                    transfer={get(dataSet, 'content.transfer', '')}
                    onlyInvestmentNotifications={true}
                    isFinalisedTx={
                      get(dataSet, 'content.progress.doneIndex') === 3
                        ? true
                        : false
                    }
                    transaction={data}
                    handleCTAClick={get(dataSet, 'handleCTAClick', '')}
                    withCTAOnCenter={isRedemptionRequest ? true : false}
                    ctaLabel={get(dataSet, 'content.cta_label', '')}
                    ctaUrl={get(dataSet, 'content.cta_url', '')}
                  />
                )
              })}

            {!isUserChannel && !onlyInvestmentNotifications && (
              <UserChannel title={'How did you discover STOKR?'} />
            )}

            {!onlyInvestmentNotifications &&
              notifications?.length > 0 &&
              notifications
                .sort(function (a, b) {
                  return a._id < b._id ? 1 : -1
                })
                .map((notificationData) => {
                  const { _id, isRead } = notificationData

                  return (
                    <NotificationItem
                      key={_id}
                      type={get(notificationData, 'type', '')}
                      title={get(notificationData, 'content.title', '')}
                      date={((d) =>
                        d ? moment(d).format('DD MMMM YYYY') : '')(
                        get(notificationData, 'date', null),
                      )}
                      text={get(notificationData, 'content.text', '')}
                      ctaLabel={get(notificationData, 'content.cta_label', '')}
                      ctaUrl={get(notificationData, 'content.cta_url', '')}
                      ctaNewTab={get(
                        notificationData,
                        'content.cta_newTab',
                        '',
                      )}
                      isRead={isRead}
                      handleCloseClick={() =>
                        toggleIsRead(notificationData._id)
                      }
                    />
                  )
                })}

            {isFakeNotification &&
              ((notifications &&
                Object.keys(notifications).length +
                  ((!isUserChannel ? 1 : 0) % 2)) ||
                (transactionsInProgress &&
                  Object.keys(transactionsInProgress).length % 2)) && (
                <FakeNotification />
              )}
          </Slick>
        </Wrapper>
      </Offset>
    </Container>
  )
}

const getTxProgressState = (tx) => {
  let doneIndex = -1
  let allSteps = allTransferSteps

  const isTokenIssued = tx.fulfilled

  if (tx.type === TransactionTypes.ISSUANCE) {
    doneIndex = 3
  } else if (isTokenIssued) {
    doneIndex = 2
  } else if (tx.investorTxStatus === 'Done' && !tx.fulfilled) {
    doneIndex = 1
  } else if (tx.investorTxStatus === 'Started' && !tx.fulfilled) {
    doneIndex = 0
  } else {
    doneIndex = -1
  }

  //REDEMPTIONS TX progress
  if (tx.isRedemption || tx.type === TransactionTypes.REDEMPTION) {
    allSteps = redemptionSteps

    if (tx.paidOut) {
      doneIndex = 2
    } else if (isTokenIssued) {
      doneIndex = 1
    } else {
      doneIndex = 0
    }
  }

  if (tx.projectName === 'techforgood') {
    allSteps = DonationTransferSteps
  }

  return { doneIndex, allSteps }
}

function FakeNotification() {
  return (
    <NotificationItem
      key={Math.random()}
      type={''}
      title={''}
      text={''}
      ctaLabel={''}
      ctaUrl={''}
      fakeNotification
    />
  )
}

NotificationsCarousel.propTypes = {
  children: PropTypes.node,
  autoplay: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  isMobile: PropTypes.bool,
  onlyInvestmentNotifications: PropTypes.bool,
  transactionsInProgress: PropTypes.bool,
}

NotificationsCarousel.defaultProps = {
  autoplay: 0,
  dots: false,
  arrows: false,
  isMobile: false,
  onlyInvestmentNotifications: false,
  transactionsInProgress: false,
}

export default NotificationsCarousel
