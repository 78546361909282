import { theme } from '@stokr/components-library/dist/styles/theme'
import styled from 'styled-components'

export const StyledChip = styled.div`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 3px;
  font-weight: 600;
  min-height: 40px;

  &:hover,
  &:active {
    background-color: red;
    color: white;
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: red;
      color: white;
      font-weight: 800;

      ${TooltipChipIcon} {
        color: ${theme.cWhite};
      }
    `}
`

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  ${({ customWrapperStyle }) => customWrapperStyle}
`

export const ChipText = styled.span`
  text-transform: uppercase;
`

export const TooltipChipIcon = styled.i.attrs({
  className: 'ion ion-md-information-circle-outline',
})`
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
  color: ${theme.cGrey2};
  transition: color 0.2s;

  ${StyledChip}:hover & {
    color: ${theme.cWhite};
  }
`
